import { useMobile } from '@/lib/hooks/useMobile';
import { useTablet } from '@/lib/hooks/useTablet';
import { useRouter } from "next/router";
import { ReactElement } from 'react';

import { ComponentProps } from '@components/ComponentProps';
import InfoBlockIcon from '@components/InfoBlockIcon';


import { InfoBlockLinkParagraph } from '@/__generated__/graphql';
import { StyledInfoBlockLink } from './InfoBlockLink.styles';

export type InfoBlockLinkProps = Omit<InfoBlockLinkParagraph & {mode: string}, 'id'>;

export default function InfoBlockLink({
  icon,
  url,
  mode
}: ComponentProps<InfoBlockLinkProps>): ReactElement {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const router = useRouter();

  return (<StyledInfoBlockLink
      className={`${mode}${(isMobile || !isTablet) ? ' even' : ''}`}
      onClick={() => router && router.push(url.url)}>
    <InfoBlockIcon icon={icon} />
    <a href={url.url} target={url.target ? url.target : undefined}>{url.title}</a>
  </StyledInfoBlockLink>);
}
