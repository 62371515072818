import { useTablet } from '@/lib/hooks/useTablet';
import { ReactElement } from 'react';

import { ComponentProps } from '@components/ComponentProps';
import InfoBlockLink from '@components/InfoBlockLink';
import InfoBlockPatterns from '@components/InfoBlockPatterns';
import { GridContainer } from "@theme/lib";

import { InfoBlockFragment } from '@/__generated__/graphql';

import { StyledInfoBlock } from './InfoBlock.styles';

export default function InfoBlock({
  infoBlockLinks
}: ComponentProps<InfoBlockFragment>): ReactElement {
  const isTablet = useTablet();

  if (!infoBlockLinks) {
    return <></>;
  }

  const modes = {
    'tablet': [ 'large', 'small' ],
    'desktop': [ 'large', 'small', 'small' ]
  };
  const blockSize = isTablet ? modes.tablet : modes.desktop;
  let count = 0;

  return (
    <StyledInfoBlock>
      <InfoBlockPatterns />
      <GridContainer className="infoBlock">
        {infoBlockLinks && infoBlockLinks.map((item, key) => {
          let currentMode = blockSize;
          if (count == blockSize.length) {
            currentMode = blockSize.reverse();
            count = 0;
          }
          return (<InfoBlockLink key={key} icon={item.icon} url={item.url} mode={currentMode[count++]} />);
        })}
      </GridContainer>
    </StyledInfoBlock>
  );
}
